<template>
    <div class="dificuldades">
        <p>Uma das maiores dificuldades que enfrentei foi o fato de nunca ter utilizado um framework reativo como Vue.js
            anteriormente. Minha experiência anterior estava enraizada no básico - HTML, CSS e JavaScript puro. Sempre
            adotei uma abordagem "feita na unha" para o desenvolvimento, onde cada linha de código era cuidadosamente 
            riada a partir do zero. No entanto, essa abordagem estava prestes a mudar.</p>
        <p>No início, minha falta de familiaridade com Vue.js trouxe consigo um conjunto de desafios inesperados.</p>
        <p>No entanto, como todo desenvolvedor sabe, a verdadeira medida de sucesso está em como enfrentamos os desafios.</p>
        <p>Aos poucos, comecei a conectar os pontos e a ver como a estrutura reativa do Vue poderia simplificar e acelerar a
            criação de interfaces de usuário dinâmicas.</p>
        <p>Com o tempo e a prática dedicada, o que inicialmente parecia uma barreira intransponível se transformou em
            um recurso valioso. Aprendi a criar componentes reutilizáveis que não apenas melhoraram a eficiência do meu
            código, mas também proporcionaram uma organização muito mais clara. A capacidade de vincular dados dinamicamente
            e assistir às mudanças automaticamente revolucionou a maneira como abordo a construção de interfaces.</p>
        <p>A medida do meu sucesso veio quando consegui integrar harmoniosamente as APIs de geolocalização e previsão do tempo
            com a lógica do Vue.js. O que antes parecia uma tarefa assustadora se tornou um processo fluido e gratificante.</p>
        <p>Hoje, posso dizer com confiança que o Vue.js não é mais uma incógnita para mim. Aprender a trabalhar com esse framework
            reativo foi uma experiência que me ensinou a abraçar a mudança e a explorar novas ferramentas. </p>

        <p>No final, apesar das dificuldades encontradas, esses desafios foram a espinha dorsal do
            meu crescimento como desenvolvedor. Eles me levaram a explorar conceitos mais aprofundados
            de integração de APIs, manipulação de dados e tratamento de erros. O conhecimento que adquiri
            nesse processo não apenas fortaleceu meu projeto, mas também enriqueceu meu conjunto de habilidades,
            preparando-me para enfrentar futuros projetos com confiança renovada.</p>
    </div>
</template>
<script>
export default{
    name: 'dificuldadesSobre'
}
</script>
<style scoped>
.dificuldades{
    padding-top: 20px;
    color: #a88cff;
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    text-indent: 20px;
    line-height: 1.5;
}
</style>