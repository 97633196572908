<template>
    <div class="aprendizado">
        <p>Hoje, posso dizer com confiança que o Vue.js não é mais uma incógnita para mim.
            Aprender a trabalhar com esse framework reativo foi uma experiência que me ensinou
            a abraçar a mudança e a explorar novas ferramentas. E agora, munido com esse conhecimento,
            tenho a intenção de incorporar o Vue.js e outras tecnologias similares em meus projetos futuros.
            Sua facilidade de uso, poder e eficiência provaram ser um ativo valioso que contribuirá para
            o crescimento contínuo da minha jornada de desenvolvimento.</p>
    </div>
</template>
<script>
export default{
    name: 'aprendizadoSobre'
}
</script>
<style scoped>
.aprendizado{
    padding-top: 20px;
    color: #7057bc;
    font-size: 16px;
    text-align: justify;
    font-weight: 400;
    text-indent: 20px;
    line-height: 1.5;
}
</style>